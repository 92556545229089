import { useCallback, useEffect, useMemo, useState } from 'react';

import Button, { ButtonProps } from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import DeletedPaymentsDialog from './components/DeletedPaymentsDialog/DeletedPaymentsDialog';
import PaymentCard from './components/PaymentCard/PaymentCard';

interface Props {
    open: boolean;
    closeDialog: (changed?: boolean) => void;
    refreshRegistrations: () => void;
    superUserId?: string;
    season: 'summer' | 'winter' | 'winter_facilities';
}

const initialData = {
    'paid': '0.00',
    'method': '',
    'invoiceNumber': ''
};

const PaymentsDialog = (props: Props) => {
    const { open, closeDialog, refreshRegistrations, superUserId, season } = props;

    const [isLoading, setIsLoading] = useState(true);

    const [data, setData] = useState<any>(initialData);
    const [savedData, setSavedData] = useState<any>(initialData);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const [isDeletedPaymentDialogOpen, setIsDeletedPaymentDialogOpen] = useState(false);

    useEffect(() => {
        if (!superUserId || !open) return;

        setIsLoading(true);
        api.request('/admin/' + (season === 'summer' ? 'summer_payouts' : 'winter_payouts') + '/' + superUserId + '/payments').then(res => {
            // setData(res);
            setSavedData(res);
            setIsLoading(false);
        });
    }, [open, superUserId, season]);

    const refreshData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/' + (season === 'summer' ? 'summer_payouts' : 'winter_payouts') + '/' + superUserId + '/payments').then(res => {
            // setData(res);
            setSavedData(res);
            setIsLoading(false);
        });
    }, [superUserId, season]);

    useEffect(() => {
        if (!open) return;

        setData((d: any) => {
            return {
                ...d,
                paid: '0.00',
                invoiceNumber: ''
            };
        });
    }, [open]);

    const saveData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/' + (season === 'summer' ? 'summer_payouts' : 'winter_payouts') + '/' + superUserId + '/payments', 'POST', data).then(() => {
            snackbar.success('Pagamento registrato correttamente!');
            refreshData();
            refreshRegistrations();
            setData((d: any) => {
                return {
                    ...d,
                    paid: '0.00',
                    invoiceNumber: ''
                };
            });
        }).finally(() => {
            setIsLoading(false);
        });
    }, [data, refreshRegistrations, refreshData, superUserId, season]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    const deletePaymentConfirm = useCallback((paymentId: string) => {
        setIsLoading(true);
        api.request('/admin/' + (season === 'summer' ? 'summer_payouts' : 'winter_payouts') + '/' + superUserId + '/payments/' + paymentId, 'DELETE').then(() => {
            snackbar.success('Pagamento eliminato correttamente!');
            refreshData();
            refreshRegistrations();
        }).finally(() => {
            setIsLoading(false);
        });
    }, [refreshData, refreshRegistrations, superUserId, season]);

    const deletePayment = useCallback((payment: any) => {
        showMessageDialog({
            title: 'Elimina pagamento',
            message: (
                <>
                    Sei sicuro di volere eliminare il pagamento di <b>€{parseFloat(payment.amount).toFixed(2)}</b>{' '}
                    registrato in data <b>{moment(payment.createdAt).format('DD/MM/YYYY')}</b>?
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Elimina pagamento',
                    color: 'secondary',
                    action: () => {
                        closeMessageDialog();
                        deletePaymentConfirm(payment.id);
                    }
                }
            ]
        });
    }, [deletePaymentConfirm, showMessageDialog, closeMessageDialog]);

    const dialogContent = useMemo(() => {
        /*const hasDiscount = savedData?.discounts?.length > 0 && (savedData.discounts.reduce((prev: number, current: any) => {
            return prev + (parseFloat(current.oldCost) - parseFloat(current.newCost));
        }, 0) > 0);*/
        const toPay = Math.max(0, parseFloat(savedData?.paymentStatus?.totalPayouts ?? 0) - parseFloat(savedData?.paymentStatus?.paidPayouts ?? 0));

        return (
            <>
                <TableContainer style={{ marginTop: '0px' }}>
                    <Table size='small'>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', padding: '4px' }}>Istruttore</TableCell>
                                <TableCell style={{ textAlign: 'right', padding: '4px' }}>{savedData?.paymentStatus?.lastName} {savedData?.paymentStatus?.firstName}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', padding: '4px' }}>Totale compensi</TableCell>
                                <TableCell style={{ textAlign: 'right', padding: '4px' }}>€ {parseFloat(savedData?.paymentStatus?.totalPayouts ?? 0).toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', padding: '4px' }}>Pagato</TableCell>
                                <TableCell style={{ textAlign: 'right', padding: '4px' }}>€ {parseFloat(savedData?.paymentStatus?.paidPayouts ?? 0).toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', padding: '4px', borderBottom: 0 }}>Da pagare</TableCell>
                                <TableCell style={{ textAlign: 'right', padding: '4px' }}>€ {parseFloat(savedData?.paymentStatus?.toPayPayouts ?? 0).toFixed(2)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Divider style={{ marginTop: '0px', marginBottom: '16px' }} />

                {((savedData?.paymentStatus?.totalPayouts ?? 0) === (savedData?.paymentStatus?.paidPayouts ?? -1) ? (
                    <Alert severity='success' style={{ marginBottom: '16px' }}>
                        I compensi per l'istruttore sono stati interamente pagati!
                    </Alert>
                ) : (
                    <>
                        <Typography variant='h5' style={{ margin: '0 0 14px', fontSize: '1.4em' }}>
                            Registra pagamento
                        </Typography>

                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: '3px', flex: 1 }}>
                                <div style={{ display: 'flex' }}>
                                    <CustomTextField
                                        style={{ marginRight: '6px', flex: 1 }}
                                        label='Importo pagamento'
                                        variant='outlined'
                                        required
                                        value={data.paid}
                                        keepMounted={true}
                                        onChange={(e) => {
                                            setData((d: any) => {
                                                return {
                                                    ...d,
                                                    paid: e.target.value
                                                };
                                            });
                                        }}
                                        onBlur={() => {
                                            setData((d: any) => {
                                                return {
                                                    ...d,
                                                    paid: Math.min(Math.max(((typeof d.paid === 'string') ? parseFloat(d.paid.replaceAll(',', '.')) : d.paid), 0), toPay).toFixed(2)
                                                };
                                            });
                                        }}
                                        disabled={isLoading}
                                    />

                                    <CustomTextField
                                        style={{ marginLeft: '6px', flex: 2 }}
                                        label='Numero fattura'
                                        variant='outlined'
                                        value={data.invoiceNumber}
                                        keepMounted={true}
                                        onChange={(e) => {
                                            setData((d: any) => {
                                                return {
                                                    ...d,
                                                    invoiceNumber: e.target.value
                                                };
                                            });
                                        }}
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>
                        </div>

                        <Button onClick={() => saveData()} color={'primary'} variant={'outlined'} style={{ margin: '0 auto', display: 'block' }}>
                            Salva
                        </Button>
                    </>
                ))}

                <Divider style={{ marginTop: '8px' }} />

                <div style={{ display: 'flex' }}>
                    <Typography variant='h5' style={{ margin: '12px 0', fontSize: '1.4em', flex: 1 }}>
                        Storico pagamenti
                    </Typography>
                </div>

                {!(savedData?.payments?.length) ? (
                    <Alert severity='warning'>
                        Nessun pagamento registrato
                    </Alert>
                ) : (
                    <>
                        {savedData.payments.map((payment: any) => (
                            <PaymentCard payment={payment} deletePayment={deletePayment} />
                        ))}
                    </>
                )}

            </>
        );
    }, [data, deletePayment, isLoading, savedData, saveData]);

    return (
        <>
            <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Gestione pagamenti'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
                {isLoading ? (
                    <Alert severity='info' style={{ marginBottom: '16px' }}>
                        Caricamento in corso...
                    </Alert>
                ) : (dialogContent)}
            </CustomDialogWrapper>
            <DeletedPaymentsDialog
                open={isDeletedPaymentDialogOpen}
                closeDialog={() => setIsDeletedPaymentDialogOpen(false)}
                payments={savedData?.deletedPayments ?? []}
            />
        </>
    );
};

export default PaymentsDialog;
