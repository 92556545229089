import { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import { Button, CircularProgress, Divider, IconButton, InputAdornment, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import { useDebounce } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Chip from '@material-ui/core/Chip';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import GenericObject from '../../../../typesAdditional/GenericObject';
import { StyledTablePagination } from './styled';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import GreenButton from '../../../../common/components/GreenButton/GreenButton';
import AddTrainerDialog from './components/AddTrainerDialog/AddTrainerDialog';
import { useMessageDialog } from '../../../../common/hooks/useMessageDialog/useMessageDialog';
import snackbar from '../../../../common/utils/snackbar';
import Alert from '@material-ui/lab/Alert';
import AvailabilityDetailDialog from './components/AvailabilityDetailDialog/AvailabilityDetailDialog';
import AvailabilityUpdateDialog from './components/AvailabilityUpdateDialog/AvailabilityUpdateDialog';
import EditWageDialog from './components/EditWageDialog/EditWageDialog';
import { useHistory } from 'react-router-dom';
import ApiEntitySelectDialog from '../../../../common/components/ApiEntitySelectDialog/ApiEntitySelectDialog';
import WinterScheduleDialog from './components/WinterScheduleDialog/WinterScheduleDialog';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux/types';
import React from 'react';

interface Props {
    season: 'summer' | 'winter' | 'public';
}

const TrainersListPage = ({ season }: Props) => {
    const setIsLoading = useInternalLoader();

    const { width } = useWindowSize();

    const userInfo = useSelector((state: ReduxState) => state.user);
    const isAdmin = !!userInfo.administrator;

    const [paginationPage, setPaginationPage] = useState(0);
    const [paginationTotal, setPaginationTotal] = useState(0);
    const [paginationItemsPerPage, setPaginationItemsPerPage] = useState(25);

    const [isSelectTrainerDialogOpen, setIsSelectTrainerDialogOpen] = useState(false);

    const [people, setPeople] = useState<GenericObject[]>([]);
    const [query, setQuery] = useState('');
    const [loadedQuery, setLoadedQuery] = useState('');
    const [loadedPage, setLoadedPage] = useState(0);
    const [loadedItemsPerPage, setLoadedItemsPerPage] = useState(25);
    const [internalLoading, setInternalLoading] = useState(false);
    const [debouncedQuery] = useDebounce(query, 500);

    const [updateInProgress, setUpdateInProgress] = useState<string[]>([]);

    const [rawData, setRawData] = useState<GenericObject>({});

    const [isAvailabilityDetailDialogOpen, setIsAvailabilityDetailDialogOpen] = useState(false);
    const [isAvailabilityUpdateDialogOpen, setIsAvailabilityUpdateDialogOpen] = useState(false);
    const [isAddTrainerDialogOpen, setIsAddTrainerDialogOpen] = useState(false);
    const [isEditWageDialogOpen, setIsEditWageDialogOpen] = useState(false);
    const [isWinterScheduleDialogOpen, setIsWinterScheduleDialogOpen] = useState(false);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const [anchor, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuGroupId, setMenuGroupId] = useState<null | string>(null);

    const [selectedTrainerId, setSelectedTrainerId] = useState<string | undefined>(undefined);

    const history = useHistory();

    const handleMenuClick = useCallback((event: React.MouseEvent<HTMLElement>, userId: string) => {
        setAnchorEl(event.currentTarget);
        setMenuGroupId(userId);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const apiRequestId = useRef('');

    useEffect(() => {
        setInternalLoading(query !== loadedQuery || paginationPage !== loadedPage || paginationItemsPerPage !== loadedItemsPerPage);
    }, [query, loadedQuery, paginationPage, loadedPage, paginationItemsPerPage, loadedItemsPerPage]);

    useEffect(() => {
        setPaginationPage(0);
    }, [debouncedQuery]);

    const refreshData = useCallback(() => {
        const currentRequestId = uuidv4();
        apiRequestId.current = currentRequestId;

        api.request('/admin/trainers', 'GET', { q: debouncedQuery, page: paginationPage + 1, perPage: paginationItemsPerPage, season }).then((res: any) => {
            if (currentRequestId !== apiRequestId.current) return;

            setRawData(res);

            const { data, total, lastPage } = res;
            setPeople(data);
            setPaginationTotal(total);
            setLoadedQuery(debouncedQuery);
            setLoadedPage(paginationPage);
            setLoadedItemsPerPage(paginationItemsPerPage);

            if (paginationPage + 1 > lastPage) {
                setPaginationPage(lastPage - 1);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, [debouncedQuery, setIsLoading, paginationPage, paginationItemsPerPage, season]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            refreshData();
        }, 10);

        return () => clearTimeout(timeout);
    }, [refreshData]);

    const disableTrainer = useCallback((trainerId: string, name: string) => {
        showMessageDialog({
            title: 'Disabilita istruttore',
            message: (
                <>
                    <p style={{ margin: '0px' }}>
                        Sei sicuro di volere disabilitare l'istruttore <b>{name}</b>?{' '}{season === 'summer' ? 'Verrà eliminata anche la sua disponibilità.' : ''}
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Conferma',
                    action: () => {
                        setUpdateInProgress(a => [...a, trainerId]);
                        closeMessageDialog();

                        api.request('/admin/trainers/' + trainerId, 'DELETE', { season }).then(() => {
                            snackbar.success('Istruttore disabilitato con successo!');

                            setIsLoading(true);
                            refreshData();
                        }).finally(() => {
                            setUpdateInProgress(a => a.filter(u => u !== trainerId));
                        });
                    }
                }
            ]
        });
    }, [closeMessageDialog, showMessageDialog, refreshData, setIsLoading, season]);

    const mapResponsible = useCallback((rawData: any) => {
        return {
            id: rawData.id,
            name: (rawData.lastName || rawData.firstName) ? ((rawData.lastName ?? '') + ' ' + (rawData.firstName ?? '')) : rawData.email,
            description: (rawData.lastName || rawData.firstName) ? rawData.email : undefined
        };
    }, []);

    const isMobile = width <= 700;

    return (
        <>
            <div>
                <div style={{ display: isMobile ? undefined : 'flex', textAlign: isMobile ? 'center' : undefined }}>
                    <Typography variant='h2' style={{ fontSize: isMobile ? '3em' : '3.6em', textAlign: isMobile ? 'center' : 'left' }}>
                        {season === 'public' ? 'Rubrica istruttori' : 'Elenco istruttori'}
                    </Typography>

                    {season !== 'public' && (
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '18px' }}>
                            <div style={{ flexGrow: 1 }} />
                            <Typography variant='h2' style={{ fontSize: '2em', marginBottom: '4px', textAlign: isMobile ? 'center' : 'left' }}>
                                {season === 'summer' ? 'estate' : 'inverno'}
                            </Typography>
                        </div>
                    )}

                    <div style={{ flex: 1, flexGrow: 1 }} />

                    {season !== 'public' && (
                        <GreenButton onClick={() => {
                            setIsSelectTrainerDialogOpen(true)
                            setSelectedTrainerId(undefined);
                        }} style={{ flexGrow: 0, marginTop: isMobile ? '10px' : undefined, marginBottom: isMobile ? '10px' : undefined }}>
                            Abilita istruttore
                        </GreenButton>
                    )}
                </div>

                <Divider style={{ margin: '8px 0 12px' }} />
            </div>

            <div style={{ display: 'flex' }}>
                <TextField
                    label='Ricerca istruttore'
                    variant='outlined'
                    placeholder='Ricerca per nome, cognome o indirizzo email'
                    value={query}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>,
                    }}
                    style={{ width: '100%', maxWidth: '600px' }}
                />

                {internalLoading && (
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '16px' }}>
                        <CircularProgress />
                    </div>
                )}

                <div style={{ flex: 1, flexGrow: 1 }} />

                {season === 'summer' && (
                    <Alert severity='info' style={{ height: 'fit-content', margin: 'auto 0' }}>
                        <b>{rawData.filledAvailabilities}</b> istruttori su <b>{rawData.totalTrainers}</b> hanno inviato le disponibilità.
                    </Alert>
                )}
            </div>

            <TableContainer component={Paper} style={{ marginTop: '16px' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: season === 'summer' ? '15.33%' : (season === 'public' ? undefined : '20%') }}>Cognome</TableCell>
                            <TableCell style={{ width: season === 'summer' ? '15.33%' : (season === 'public' ? undefined : '20%') }}>Nome</TableCell>

                            {season === 'public' && (
                                <>
                                    <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Cellulare</TableCell>
                                    <TableCell></TableCell>
                                </>
                            )}

                            <TableCell style={{ width: season === 'summer' ? '15.33%' : (season === 'public' ? undefined : '20%') }}>Indirizzo email</TableCell>

                            {season !== 'public' && (
                                <TableCell style={{ width: season === 'summer' ? '15.33%' : '20%' }}>Residenza</TableCell>
                            )}

                            {season === 'summer' && (
                                <>
                                    <TableCell style={{ width: season === 'summer' ? '15.33%' : '20%' }}>Diete speciali</TableCell>
                                    <TableCell style={{ width: season === 'summer' ? '15.33%' : '20%' }}>Stato</TableCell>
                                    <TableCell style={{ width: '8%' }}>Responsabile</TableCell>
                                </>
                            )}

                            {season === 'winter' && (
                                <TableCell style={{ width: '20%' }}>Compenso orario</TableCell>
                            )}

                            {(season !== 'public' || isAdmin) && (
                                <TableCell style={{ width: '84px', whiteSpace: 'nowrap' }}>Azioni</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {people.map(person => {
                            type status = 'waiting_first_login' | 'waiting_availability' | 'availability_ok' | 'pending_edit'

                            const statusMap = {
                                waiting_first_login: {
                                    color: '#ffffff',
                                    borderColor: '#000000',
                                    label: 'In attesa del primo accesso',
                                    outlined: true
                                },
                                waiting_availability: {
                                    color: '#fdecea',
                                    borderColor: '#f44336',
                                    label: 'Disponibilità non compilata',
                                    outlined: false
                                },
                                availability_ok: {
                                    color: '#edf7ed',
                                    borderColor: '#4caf50',
                                    label: 'Disponibilità compilata',
                                    outlined: false
                                },
                                pending_edit: {
                                    color: '#fff4e5',
                                    borderColor: '#ff9800',
                                    label: 'Modifica in attesa',
                                    outlined: false
                                }
                            }

                            const responsibleMap = {
                                0: {
                                    color: '#ffffff',
                                    borderColor: '#000000',
                                    label: 'No',
                                    outlined: true
                                },
                                1: {
                                    color: '#edf7ed',
                                    borderColor: '#4caf50',
                                    label: 'Sì',
                                    outlined: false
                                }
                            }

                            const statusData = statusMap[person.status as status];
                            const responsibleData = responsibleMap[(person.isResponsible ? 1 : 0)];

                            return (
                                <TableRow style={{ height: '45px' }}>
                                    <TableCell>{internalLoading ? <Skeleton variant='text' /> : person.lastName}</TableCell>
                                    <TableCell>{internalLoading ? <Skeleton variant='text' /> : person.firstName}</TableCell>

                                    {season === 'public' && (
                                        <TableCell style={{ width: '1px', paddingRight: 0, whiteSpace: 'nowrap' }}>{internalLoading ? <Skeleton variant='text' /> : (<>
                                            {person?.phone && (
                                                <>
                                                    <Link target='_blank' href={'tel:' + person?.phone}>{person?.phone}</Link>
                                                </>
                                            )}
                                        </>)}</TableCell>
                                    )}

                                    {season === 'public' && (
                                        <TableCell>{internalLoading ? <Skeleton variant='text' /> : (<>
                                            {person?.phone && (
                                                <>
                                                    {/* @ts-ignore */}
                                                    <Button component={Link} target='_blank' href={"whatsapp://send?phone=" + (person?.phone?.substring(1) ?? '')} color={'primary'} variant='outlined' size='small' style={{ textDecoration: 'none', color: '#128C7E', border: '1px solid #128C7E', textAlign: 'center' }}>
                                                        WhatsApp
                                                    </Button>
                                                </>
                                            )}
                                        </>)}</TableCell>
                                    )}

                                    <TableCell>{internalLoading ? <Skeleton variant='text' /> : (
                                        <>
                                            {season === 'public' ? (
                                                <Link target='_blank' href={'mailto:' + person.email}>{person.email}</Link>
                                            ) : person.email}
                                        </>
                                    )}</TableCell>

                                    {season !== 'public' && (
                                        <TableCell>{internalLoading ? <Skeleton variant='text' /> : (person?.residence?.name ?? '')}</TableCell>
                                    )}

                                    {season === 'summer' && (
                                        <>
                                            <TableCell>{internalLoading ? <Skeleton variant='text' /> : (person?.specialDietsText)}</TableCell>

                                            <TableCell>{internalLoading ? <Skeleton variant='text' /> : (
                                                <>
                                                    <Chip label={statusData.label} variant={statusData.outlined ? 'outlined' : 'default'} style={statusData.outlined ? { width: '200px' } : { backgroundColor: statusData.color, border: '1px solid ' + statusData.borderColor, width: '200px' }} />
                                                </>
                                            )}
                                            </TableCell>
                                            <TableCell>{internalLoading ? <Skeleton variant='text' /> : (
                                                <>
                                                    <Chip label={responsibleData.label} variant={responsibleData.outlined ? 'outlined' : 'default'} style={responsibleData.outlined ? { width: '70px' } : { backgroundColor: responsibleData.color, border: '1px solid ' + responsibleData.borderColor, width: '70px' }} />
                                                </>
                                            )}
                                            </TableCell>
                                        </>
                                    )}

                                    {season === 'winter' && (
                                        <TableCell>
                                            {(parseFloat(person.defaultWinterWage ? person.defaultWinterWage : 0) + ' €/ora')}
                                            {person.otherWinterWages.length > 0 && (
                                                <>
                                                    {' - '}
                                                    {person.otherWinterWages.map((x: any) => parseFloat(x.hourlyWage) + ' €/ora').join(' - ')}
                                                </>
                                            )}
                                        </TableCell>
                                    )}

                                    {(season !== 'public' || isAdmin) && (
                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>
                                            {updateInProgress.includes(person.id) ? (
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <CircularProgress style={{ width: '22px', height: '22px' }} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <IconButton onClick={(e: any) => handleMenuClick(e, person.id)} style={{ padding: '2px 14px' }}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            )}
                                            <Menu
                                                anchorEl={anchor}
                                                keepMounted
                                                open={!!anchor && menuGroupId === person.id}
                                                onClose={handleClose}
                                            >
                                                {/*
                                            {person.status === 'waiting_first_login' && (
                                                <MenuItem disabled={updateInProgress.includes(person.id)} onClick={() => {
                                                    handleClose();
                                                }}>
                                                    Reimposta password
                                                </MenuItem>
                                            )}
                                            */}

                                                <MenuItem disabled={updateInProgress.includes(person.id)} onClick={() => {
                                                    handleClose();
                                                    setSelectedTrainerId(person.id);
                                                    setIsAddTrainerDialogOpen(true);
                                                }}>
                                                    Modifica dati anagrafici
                                                </MenuItem>

                                                {season !== 'public' && (
                                                    <>
                                                        {season === 'summer' && (
                                                            <>
                                                                <MenuItem disabled={updateInProgress.includes(person.id)} onClick={() => {
                                                                    handleClose();
                                                                    setSelectedTrainerId(person.id);
                                                                    setIsAvailabilityDetailDialogOpen(true);
                                                                }}>
                                                                    Visualizza disponibilità
                                                                </MenuItem>

                                                                <MenuItem disabled={updateInProgress.includes(person.id)} onClick={() => {
                                                                    handleClose();
                                                                    setSelectedTrainerId(person.id);
                                                                    setIsAvailabilityUpdateDialogOpen(true);
                                                                }}>
                                                                    Modifica disponibilità
                                                                </MenuItem>
                                                            </>
                                                        )}

                                                        {season === 'winter' && (
                                                            <MenuItem disabled={updateInProgress.includes(person.id)} onClick={() => {
                                                                handleClose();
                                                                setSelectedTrainerId(person.id);
                                                                setIsWinterScheduleDialogOpen(true);
                                                            }}>
                                                                Visualizza utilizzo
                                                            </MenuItem>
                                                        )}

                                                        {season === 'winter' && (
                                                            <MenuItem disabled={updateInProgress.includes(person.id)} onClick={() => {
                                                                handleClose();
                                                                setSelectedTrainerId(person.id);
                                                                setIsEditWageDialogOpen(true);
                                                            }}>
                                                                Modifica compenso orario
                                                            </MenuItem>
                                                        )}

                                                        <MenuItem disabled={updateInProgress.includes(person.id)} onClick={() => {
                                                            handleClose();
                                                            history.push((window.location.host.startsWith('admin.epuntos.it') ? '' : '/admin') + ('/staff-winter/payouts-report/' + person.id) + '/' + (season === 'summer' ? 2023 : 2024));
                                                        }}>
                                                            Report compensi
                                                        </MenuItem>

                                                        <MenuItem disabled={updateInProgress.includes(person.id)} onClick={() => {
                                                            handleClose();
                                                            disableTrainer(person.id, person.firstName + ' ' + person.lastName)
                                                        }}>
                                                            Disabilita
                                                        </MenuItem>
                                                    </>
                                                )}
                                            </Menu>
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <StyledTablePagination
                count={paginationTotal}
                page={paginationPage}
                onPageChange={(e: any, page: number) => setPaginationPage(page)}
                rowsPerPage={paginationItemsPerPage}
                onChangeRowsPerPage={(e: any) => setPaginationItemsPerPage(parseInt(e.target.value, 10))}
                // @ts-ignore
                component='div'
                backIconButtonText='Pagina precedente'
                nextIconButtonText='Pagina successiva'
                labelRowsPerPage='Righe'
                labelDisplayedRows={({ from, to, count }) => {
                    return `${from} - ${to} di ${count}`;
                }}
            />

            <AvailabilityDetailDialog
                open={isAvailabilityDetailDialogOpen}
                closeDialog={() => setIsAvailabilityDetailDialogOpen(false)}
                selectedTrainerId={selectedTrainerId}
            />

            <AvailabilityUpdateDialog
                open={isAvailabilityUpdateDialogOpen}
                closeDialog={(shouldRefresh?: boolean) => {
                    setIsAvailabilityUpdateDialogOpen(false);

                    if (shouldRefresh) refreshData();
                }}
                selectedTrainerId={selectedTrainerId}
            />

            <ApiEntitySelectDialog
                open={isSelectTrainerDialogOpen}
                onClose={(selectedId?: string) => {
                    setIsSelectTrainerDialogOpen(false);

                    if (selectedId) {
                        api.request('/admin/trainers/enable/' + selectedId, 'PUT', { season }).then(res => {
                            snackbar.success('Istruttore abilitato con successo!');
                            refreshData();
                        });
                    }
                }}
                title='Abilita istruttore'
                url={'/admin/trainers/not_enabled/'}
                map={mapResponsible}
                noEntityFoundMessage='Nessun istruttore trovato'
                newEntityBtnText='Nuovo istruttore'
                newEntityBtnPosition='footer'
                onNewEntityClick={() => {
                    setIsSelectTrainerDialogOpen(false);
                    setIsAddTrainerDialogOpen(true);
                }}
                params={{ season }}
            />

            <AddTrainerDialog
                open={isAddTrainerDialogOpen}
                closeDialog={(shouldRefresh?: boolean) => {
                    setIsAddTrainerDialogOpen(false)

                    if (shouldRefresh) refreshData();
                }}
                selectedTrainerId={selectedTrainerId}
                enableForSummer={season === 'summer'}
                enableForWinter={season === 'winter'}
            />

            <EditWageDialog
                open={isEditWageDialogOpen}
                closeDialog={(shouldRefresh?: boolean) => {
                    setIsEditWageDialogOpen(false);

                    if (shouldRefresh) refreshData();
                }}
                selectedTrainer={people.find(x => x.id === selectedTrainerId)}
                selectedTrainerId={selectedTrainerId}
            />

            <WinterScheduleDialog
                open={isWinterScheduleDialogOpen}
                closeDialog={() => setIsWinterScheduleDialogOpen(false)}
                selectedTrainerId={selectedTrainerId}
            />
        </>
    );
};

export default TrainersListPage;
