import { useEffect, useMemo, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import { LinearProgress, Table, TableCell, TableRow } from '@material-ui/core';
import api from '../../../../../../common/utils/api';
import Scheduler from "@mormat/react-scheduler";
import React from 'react';
import moment from 'moment';

interface Props {
    open: boolean;
    closeDialog: () => void;
    selectedTrainerId?: string;
    overrideDialogTitle?: string;
}

const WinterScheduleDialog = (props: Props) => {
    const { open, closeDialog, selectedTrainerId, overrideDialogTitle } = props;

    const [data, setData] = useState<GenericObject[]>([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!selectedTrainerId || !open) return;

        setIsLoading(true);

        api.request('/admin/winter_staff/schedule/trainers/' + selectedTrainerId).then(res => {
            setData(res);
            setIsLoading(false);
        });
    }, [selectedTrainerId, open]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    const getTextColor = (background: string) => {
        const r = parseInt(background.slice(1, 3), 16);
        const g = parseInt(background.slice(3, 5), 16);
        const b = parseInt(background.slice(5, 7), 16);

        if ((r * 0.299) + (g * 0.587) + (b * 0.114) > 186) {
            //console.log('black ' + background);
            return '#000000';
        } else {
            //console.log('white ' + background)
            return '#ffffff';
        }
    }

    const events = useMemo(() => {
        return data.map((event) => {
            return {
                label: event.courseSession.location.course.name === event.courseSession.location.townName ? event.courseSession.location.course.name : (event.courseSession.location.course.name + ' ' + event.courseSession.location.townName),
                start: event.realStartTime,
                end: event.realEndTime,
                bgColor: '#' + event.courseSession.location.scheduleColor,
                color: getTextColor('#' + event.courseSession.location.scheduleColor)
            }
        })
    }, [data])

    const minimumStartHour = useMemo(() => {
        const minimum = Math.min(...events.map((event: any) => {
            return parseInt(moment(event.start + ':00').format('HH'));
        }));

        return Math.min(minimum, 15);
    }, [events]);

    const maximumEndHour = useMemo(() => {
        const maximum = Math.max(...events.map((event: any) => {
            return parseInt(moment(event.end + ':00').format('HH'));
        })) + 1;

        return Math.max(maximum, 20);
    }, [events]);

    const countHours = useMemo(() => {
        return maximumEndHour - minimumStartHour;
    }, [minimumStartHour, maximumEndHour]);

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={overrideDialogTitle ?? 'Utilizzo istruttore'} isLoading={isLoading} buttons={buttons} maxWidth='xl' contentProps={{ style: { display: !isLoading ? 'flex' : undefined, width: '880px' } }}>
            {(isLoading) ? (
                <LinearProgress style={{ margin: '8px 0' }} />
            ) : (
                <>
                    <div>
                        <Table>
                            <TableRow>
                                <TableCell style={{ width: '46px', borderBottom: 0 }} />
                                <TableCell style={{ borderBottom: 0, fontWeight: 'bold', textAlign: 'center' }}>LUNEDÌ</TableCell>
                                <TableCell style={{ borderBottom: 0, fontWeight: 'bold', textAlign: 'center' }}>MARTEDÌ</TableCell>
                                <TableCell style={{ borderBottom: 0, fontWeight: 'bold', textAlign: 'center' }}>MERCOLEDÌ</TableCell>
                                <TableCell style={{ borderBottom: 0, fontWeight: 'bold', textAlign: 'center' }}>GIOVEDÌ</TableCell>
                                <TableCell style={{ borderBottom: 0, fontWeight: 'bold', textAlign: 'center' }}>VENERDÌ</TableCell>
                                <TableCell style={{ borderBottom: 0, fontWeight: 'bold', textAlign: 'center' }}>SABATO</TableCell>
                                <TableCell style={{ borderBottom: 0, fontWeight: 'bold', textAlign: 'center' }}>DOMENICA</TableCell>
                            </TableRow>
                        </Table>
                        <Scheduler
                            events={events}
                            initialDate="1970-01-05"
                            editable={false}
                            draggable={false}
                            minHour={minimumStartHour}
                            maxHour={maximumEndHour}
                            height={85 * countHours}
                        />
                    </div>
                </>
            )}


        </CustomDialogWrapper >
    );
};

export default WinterScheduleDialog;
