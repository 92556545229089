import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'react-use';

import { Button, Divider, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import { PageContainer } from './styled';
import GreenButton from '../../../../common/components/GreenButton/GreenButton';
import { useMessageDialog } from '../../../../common/hooks/useMessageDialog/useMessageDialog';
import snackbar from '../../../../common/utils/snackbar';
import Footer from './components/Footer/Footer';
import moment from 'moment';
import 'moment/locale/it';
import { ReduxState } from '../../../../redux/types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ChangeExtraWageDialog from './components/ChangeExtraWageDialog/ChangeExtraWageDialog';
import CustomTextField from '../../../../common/components/CustomTextField/CustomTextField';

interface Props {
    season: 'summer' | 'winter';
}

const WinterDetailedTimetablePage = ({ season }: Props) => {
    const { trainerId, month } = useParams<{ trainerId?: string, month: string }>();

    const setIsLoading = useInternalLoader();

    const { width } = useWindowSize();

    const [info, setInfo] = useState<any>({});

    const userInfo = useSelector((state: ReduxState) => state.user);
    const isAdmin = userInfo?.administrator ?? false;
    const canManageStaff = userInfo?.canManageStaff ?? false;

    const [computeUntil, setComputeUntil] = useState(isAdmin ? 'eoy' : 'today');

    const realTrainerId = trainerId ?? userInfo.id;

    const selectedMonthPaychecks = useMemo(() => {
        if (!info.paychecks) return [];

        return info.paychecks.filter((p: any) => {
            return parseInt(moment(p.day, 'YYYY-mm-dd').format('m')) === parseInt(month);
        });
    }, [info, month]);

    const schoolCourses = useMemo(() => {
        return [...new Set(selectedMonthPaychecks.filter((p: any) => p.courseCategorySlug === 'school_courses').map((p: any) => p.courseName))] as string[];
    }, [selectedMonthPaychecks]);

    const [zoomLevel, setZoomLevel] = useState(1);

    const setZoomLevelImpl = useCallback((zoom: string) => {
        setZoomLevel(Math.min(Math.max(parseFloat(zoom.replaceAll('%', '')) / 100, 0.2), 1.6));
    }, []);

    const decrementZoom = useCallback(() => {
        setZoomLevel(actual => Math.max(actual - 0.1, 0.2))
    }, []);

    const incrementZoom = useCallback(() => {
        setZoomLevel(actual => Math.min(actual + 0.1, 1.6))
    }, []);

    const [isChangeExtraWageDialogOpen, setIsChangeExtraWageDialogOpen] = useState(false);
    const [extraWageId, setExtraWageId] = useState<string | undefined>(undefined);
    const [updateInProgress, setUpdateInProgress] = useState<string[]>([]);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const refreshData = useCallback(() => {
        api.request('/admin/winter_staff/timetable/' + realTrainerId, 'GET', { computeUntil }).then(res => {
            setInfo(res);
            setIsLoading(false);
        })
    }, [setIsLoading, realTrainerId, computeUntil]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            refreshData();
        }, 10);

        return () => clearTimeout(timeout);
    }, [refreshData]);

    const deleteExtraWage = useCallback((wageId: string) => {
        showMessageDialog({
            title: 'Elimina compenso',
            message: (
                <>
                    <p style={{ margin: '0px' }}>
                        Sei sicuro di volere eliminare il compenso selezionato?
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Conferma',
                    action: () => {
                        setUpdateInProgress(a => [...a, wageId]);
                        closeMessageDialog();

                        api.request('/admin/winter_staff/extra_wages/' + wageId, 'DELETE', { season }).then(() => {
                            snackbar.success('Compenso eliminato con successo!');

                            setIsLoading(true);
                            refreshData();
                        }).finally(() => {
                            setUpdateInProgress(a => a.filter(u => u !== wageId));
                        });
                    }
                }
            ]
        });
    }, [closeMessageDialog, showMessageDialog, refreshData, setIsLoading, season]);

    const isMobile = width <= 700;

    return (
        <PageContainer>
            <div>
                <div style={{ display: isMobile ? undefined : 'flex', textAlign: isMobile ? 'center' : undefined }}>
                    <Typography variant='h2' style={{ fontSize: isMobile ? '3em' : '3.6em', textAlign: isMobile ? 'center' : 'left' }}>
                        {info?.superUser ? (info.superUser.lastName + ' ' + info.superUser.firstName) : ''}
                    </Typography>

                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '18px' }}>
                        <div style={{ flexGrow: 1 }} />
                        <Typography variant='h2' style={{ fontSize: '2em', marginBottom: '4px', textAlign: isMobile ? 'center' : 'left' }}>
                            compensi 2024/2025
                        </Typography>
                    </div>

                    <div style={{ flex: 1, flexGrow: 1 }} />

                    <CustomTextField
                        select
                        label={'Calcola compensi fino a'}
                        value={computeUntil}
                        variant='outlined'
                        onChange={(e: any) => {
                            setComputeUntil(e.target.value);
                        }}
                        style={{ width: '180px', marginBottom: 0, marginRight: '16px' }}
                    >
                        <MenuItem value={'today'} style={{ whiteSpace: 'break-spaces' }}>
                            oggi
                        </MenuItem>

                        <MenuItem value={'eoy'} style={{ whiteSpace: 'break-spaces' }}>
                            giugno 2025
                        </MenuItem>
                    </CustomTextField>

                    {isAdmin && canManageStaff && (
                        <GreenButton onClick={() => {
                            setExtraWageId(undefined);
                            setIsChangeExtraWageDialogOpen(true);
                        }} style={{ flexGrow: 0, marginTop: isMobile ? '10px' : undefined, marginBottom: isMobile ? '10px' : undefined }}>
                            Aggiungi compenso
                        </GreenButton>
                    )}

                </div>

                <Divider style={{ margin: '8px 0 12px' }} />
            </div>

            <div style={{ flexGrow: 1, overflowY: 'auto', marginRight: '16px', width: 'calc(100% - 16px)' }}>
                <div style={{ maxHeight: '100%', paddingBottom: '16px', display: 'flex', marginLeft: '10px', overflowX: isMobile ? 'auto' : undefined, scale: (isMobile ? 1 : zoomLevel).toString(), transformOrigin: '0 0', marginBottom: -Math.max(0, ((1 - (isMobile ? 1 : zoomLevel)) * 100 / 2)) + '%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <TableContainer component={Paper} style={{ marginTop: '16px', width: 'auto', flexGrow: 1 }}>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Data</TableCell>
                                        <TableCell>Ore</TableCell>
                                        <TableCell>Corso</TableCell>
                                        <TableCell>Luogo</TableCell>

                                        {schoolCourses.map((course: string) => {
                                            return (
                                                <TableCell>{course}</TableCell>
                                            )
                                        })}

                                        <TableCell>Tecnico sostituito</TableCell>
                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }} />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedMonthPaychecks.map((p: any) => {
                                        return (
                                            <TableRow style={{ backgroundColor: p.extraWageId ? '#e8f4fd' : (p.futureDate ? '#fff4e5' : undefined) }}>
                                                <TableCell>
                                                    {moment(p.day, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                                </TableCell>
                                                <TableCell>
                                                    {p.courseCategorySlug !== 'school_courses' ? (Math.round(p.seconds / 3600 * 100) / 100) : ''}
                                                    {p.extraWageTypeAmount ? (
                                                        <>
                                                            {' '}(€ {p.payout})
                                                        </>
                                                    ) : ''}
                                                </TableCell>
                                                <TableCell>
                                                    {(p.courseCategorySlug !== 'school_courses' && p.courseName) ? p.courseName : ''}
                                                </TableCell>
                                                <TableCell>
                                                    {p.courseCategorySlug !== 'school_courses' ? p.townName : ''}
                                                </TableCell>

                                                {schoolCourses.map((course: string) => {
                                                    return (
                                                        <TableCell>{p.courseCategorySlug === 'school_courses' && p.courseName === course ? (Math.round(p.seconds / 3600 * 100) / 100) : ''}</TableCell>
                                                    )
                                                })}

                                                <TableCell>
                                                    {p.replacedTrainer ? (p.replacedTrainer.firstName + ' ' + p.replacedTrainer.lastName) : ''}
                                                </TableCell>

                                                <TableCell style={{ width: '1px', whiteSpace: 'nowrap', paddingTop: 0, paddingBottom: 0 }}>
                                                    {p.extraWageId && isAdmin && canManageStaff && (
                                                        <>
                                                            <Button onClick={() => {
                                                                setExtraWageId(p.extraWageId);
                                                                setIsChangeExtraWageDialogOpen(true);
                                                            }} disabled={updateInProgress.includes(p.extraWageId)} size='small' variant='outlined' color='primary' style={{ backgroundColor: 'white' }}>Modifica</Button>
                                                            <Button onClick={() => {
                                                                deleteExtraWage(p.extraWageId);
                                                            }} disabled={updateInProgress.includes(p.extraWageId)} size='small' variant='outlined' color='secondary' style={{ backgroundColor: 'white', marginLeft: '6px' }}>
                                                                Elimina
                                                            </Button>
                                                        </>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}

                                    <TableRow>
                                        <TableCell>
                                            <b>TOTALE</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>
                                                {Math.round(selectedMonthPaychecks.filter((p: any) => p.courseCategorySlug !== 'school_courses').reduce((prev: any, curr: any) => {
                                                    return prev + curr.seconds;
                                                }, 0) / 3600 * 100) / 100}
                                            </b>
                                        </TableCell>
                                        <TableCell />

                                        {schoolCourses.map((course: string) => {
                                            return (
                                                <TableCell>
                                                    <b>
                                                        {Math.round(selectedMonthPaychecks.filter((p: any) => p.courseCategorySlug === 'school_courses' && p.courseName === course).reduce((prev: any, curr: any) => {
                                                            return prev + curr.seconds;
                                                        }, 0) / 3600 * 100) / 100}
                                                    </b>
                                                </TableCell>
                                            )
                                        })}

                                        <TableCell />
                                        <TableCell />
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/*
                        <div style={{ flexGrow: 0, padding: '16px' }}>
                            ciaoooo
                        </div>
                        */}
                    </div>
                </div>
            </div>

            <Footer superUserId={realTrainerId} selectedTab={month} incrementZoom={incrementZoom} decrementZoom={decrementZoom} zoomLevel={zoomLevel} setZoomLevel={setZoomLevelImpl} isWeekVisible={true} />

            <ChangeExtraWageDialog
                open={isChangeExtraWageDialogOpen}
                closeDialog={(shouldRefresh?: boolean) => {
                    setIsChangeExtraWageDialogOpen(false);

                    if (shouldRefresh) refreshData();
                }}
                superUserId={realTrainerId}
                extraWageId={extraWageId}
                month={month}
            />
        </PageContainer>
    );
};

export default WinterDetailedTimetablePage;
