import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { MenuItem } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import { StyledTextField } from '../../../../../../common/components/CustomTextField/styled';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import TownAutoComplete from '../../../../../public/pages/registrationPage/components/GenericPersonForm/sections/address/components/TownAutocomplete/TownAutocomplete';
import SpecialDietsV2Selector from '../../../../../public/pages/registrationPage/steps/RegistrationStep6/components/SpecialDietsV2Selector/SpecialDietsV2Selector';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import { v4 as uuidv4 } from 'uuid';
import useValidatePhoneNumber from '../../../../../public/pages/registrationPage/components/GenericPersonForm/hooks/useValidatePhoneNumber';
import IBAN from 'iban';

interface Props {
    open: boolean;
    closeDialog: (reloadData?: boolean, superUserId?: string) => void;
    selectedTrainerId?: string;
    enableForSummer?: boolean;
    enableForWinter?: boolean;
    minimalForm?: boolean;
    overrideTitle?: string;
}

const AddTrainerDialog = (props: Props) => {
    const { open, closeDialog, selectedTrainerId, enableForSummer, enableForWinter, minimalForm, overrideTitle } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [taxCode, setTaxCode] = useState('');
    const [phone, setPhone] = useState('');
    const [iban, setIban] = useState('');
    const [isResponsible, setIsResponsible] = useState(false);
    const [townLegalId, setTownLegalId] = useState<string | null>(null);
    const [specialDiets, setSpecialDiets] = useState<GenericObject[]>([]);

    const searchApiRequestId = useRef('');
    const [searchResult, setSearchResult] = useState<any[]>([]);

    const { phone: isPhoneValid } = useValidatePhoneNumber({ formPrefix: 'dummy', data: { dummy: { phone } } });

    const dummyTownData = useMemo(() => {
        return {
            dummy: {
                address: {
                    town_legal_id: townLegalId
                }
            }
        };
    }, [townLegalId]);

    const dummyTownRawOnChange = useCallback((path: string, value: string) => {
        if (path === 'dummy.address.town_legal_id') {
            setTownLegalId(value);
        }
    }, []);

    const randomString = (length = 6) => {
        let result = '';
        const characters = 'ABCDEFGHLMNPQRSTUVZ23456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    useEffect(() => {
        setName('');
        setSurname('');
        setTaxCode('');
        setPhone('');
        setIban('');
        setEmail('');
        setTownLegalId(null);
        setIsResponsible(false);
        setSpecialDiets([]);
        setPassword('EPUNTOS-' + randomString(6));
    }, [open]);

    useEffect(() => {
        const currentRequestId = uuidv4();
        searchApiRequestId.current = currentRequestId;

        api.request('/admin/trainers', 'GET', { q: email, page: 1, perPage: 5 }).then((res) => {
            if (currentRequestId !== searchApiRequestId.current) return;

            setSearchResult(res.data);
        });

    }, [email]);

    const userExist = useMemo(() => {
        return searchResult.find((u: any) => u.email === email);
    }, [searchResult, email]);

    useEffect(() => {
        if (!open || !selectedTrainerId) return;

        setIsLoading(true);

        api.request('/admin/trainers/' + selectedTrainerId).then((res) => {
            setName(res.name);
            setSurname(res.surname);
            setTaxCode(res.taxCode);
            setPhone(res.phone);
            setIban(res.iban);
            setIsResponsible(res.isResponsible);
            setTownLegalId(res.residenceLegalId);
            setSpecialDiets(res.specialDiets);
            setIsLoading(false);
        });
    }, [open, selectedTrainerId])

    const handleSubmit = useCallback(() => {
        setIsLoading(true);

        if (selectedTrainerId) {
            api.request('/admin/trainers/' + selectedTrainerId, 'POST', { name, surname, taxCode, phone, iban, townLegalId, specialDiets, isResponsible, enableForSummer, enableForWinter }).then((res) => {
                snackbar.success('Istruttore modificato con successo!');
                closeDialog(true, res.id);
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            api.request('/admin/trainers', 'POST', { name, surname, taxCode, phone, iban, email, password, townLegalId, isResponsible, enableForSummer, enableForWinter }).then((res) => {
                snackbar.success('Istruttore aggiunto con successo!');
                closeDialog(true, res.id);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [closeDialog, name, surname, taxCode, phone, iban, email, password, townLegalId, specialDiets, isResponsible, selectedTrainerId, enableForSummer, enableForWinter]);

    const handleKeyDown = useCallback((e) => {
        if (e.keyCode === 13) {
            handleSubmit();
        }
    }, [handleSubmit]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'secondary',
            onClick: () => closeDialog()
        },
        {
            children: selectedTrainerId ? 'Salva' : 'Aggiungi',
            color: 'primary',
            onClick: () => handleSubmit()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={overrideTitle ? overrideTitle : (selectedTrainerId ? 'Modifica istruttore' : 'Aggiungi istruttore')} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <div style={{ display: 'flex' }}>
                <StyledTextField
                    label={'Nome'}
                    variant='outlined'
                    value={(userExist && !name) ? userExist.firstName : name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={handleKeyDown}
                    style={{ marginRight: '6px' }}
                    fullWidth
                    disabled={isLoading || userExist}
                />

                <StyledTextField
                    label={'Cognome'}
                    variant='outlined'
                    value={(userExist && !surname) ? userExist.lastName : surname}
                    onChange={(e) => setSurname(e.target.value)}
                    onKeyDown={handleKeyDown}
                    style={{ marginLeft: '6px' }}
                    fullWidth
                    disabled={isLoading || userExist}
                />
            </div>

            <StyledTextField
                label={'Cellulare'}
                variant='outlined'
                value={(userExist && !phone) ? userExist.phone : phone}
                fullWidth
                disabled={isLoading}
                error={!isPhoneValid && !!phone}
                style={{ marginBottom: '12px', marginRight: '4px' }}
                onChange={(e: any) => setPhone(e.target.value)}
                key={isLoading ? 'a0' : 'b0'}
            />

            <div style={{ display: 'flex', marginBottom: '8px' }}>
                <StyledTextField
                    label={'Codice fiscale'}
                    variant='outlined'
                    value={(userExist && !taxCode) ? userExist.taxCode : taxCode}
                    fullWidth
                    disabled={isLoading}
                    style={{ marginBottom: '4px', marginRight: '4px', flex: 3 }}
                    onChange={(e: any) => setTaxCode(e.target.value)}
                    key={isLoading ? 'a2' : 'b2'}
                />

                <StyledTextField
                    label={'IBAN'}
                    variant='outlined'
                    value={(userExist && !iban) ? userExist.iban : iban}
                    fullWidth
                    disabled={isLoading}
                    error={!IBAN.isValid(iban ?? '') && !!iban}
                    style={{ marginBottom: '4px', marginLeft: '4px', flex: 4 }}
                    onChange={(e: any) => setIban(e.target.value)}
                    key={isLoading ? 'a1' : 'b1'}
                />
            </div>

            {!selectedTrainerId && (
                <div style={{ display: 'flex', marginTop: '4px', marginBottom: '8px' }}>
                    <StyledTextField
                        label={'Indirizzo email'}
                        variant='outlined'
                        type='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={handleKeyDown}
                        style={{ marginRight: '6px', marginBottom: '4px' }}
                        fullWidth
                        disabled={isLoading}
                    />

                    <StyledTextField
                        label={'Password temporanea'}
                        variant='outlined'
                        value={userExist ? '******' : password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown}
                        style={{ marginLeft: '6px', marginBottom: '4px' }}
                        fullWidth
                        disabled={isLoading || userExist}
                    />
                </div>
            )}

            {!minimalForm && (
                <TownAutoComplete data={dummyTownData} formPrefix={'dummy'} rawOnChange={dummyTownRawOnChange} />
            )}

            {(!minimalForm && !enableForWinter && enableForSummer) && (
                <>
                    {selectedTrainerId && (
                        <SpecialDietsV2Selector
                            selectedSpecialDiets={specialDiets}
                            rawOnChange={(field, value) => {
                                setSpecialDiets(value)
                            }}
                            disabled={false}
                        />
                    )}

                    <StyledTextField
                        label={'Responsabile'}
                        variant='outlined'
                        onKeyDown={handleKeyDown}
                        style={{ marginTop: '14px', marginBottom: '4px' }}
                        value={isResponsible ? 'yes' : 'no'}
                        onChange={(e: any) => {
                            setIsResponsible(e.target.value === 'yes');
                        }}
                        fullWidth
                        select
                        disabled={isLoading}
                    >
                        <MenuItem key={'yes'} value={'yes'}>
                            sì
                        </MenuItem>
                        <MenuItem key={'no'} value={'no'}>
                            no
                        </MenuItem>
                    </StyledTextField>
                </>
            )}
        </CustomDialogWrapper>
    );
};

export default AddTrainerDialog;
