import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'react-use';

import { Button, Divider, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import { PageContainer } from './styled';
import Footer from './components/Footer/Footer';
import moment from 'moment';
import 'moment/locale/it';
import { ReduxState } from '../../../../redux/types';
import { useSelector } from 'react-redux';
import DynamicTooltipFacilityName from './components/DynamicTooltipFacilityName/DynamicTooltipFacilityName';
import AddIcon from '@material-ui/icons/Add';
import SelectFacilityDialog from './components/SelectFacilityDialog/SelectFacilityDialog';
import SelectTrainerDialog from '../winterGeneralSchedulePage/components/SelectTrainerDialog/SelectTrainerDialog';
import { useParams } from 'react-router-dom';
import { StyledListItem } from '../winterGeneralSchedulePage/WinterGeneralSchedulePage';

const WinterSchedulePage = () => {
    const { townId, courseLocationId } = useParams<{ townId: string, courseLocationId: string }>();

    const setIsLoading = useInternalLoader();

    const { width } = useWindowSize();

    const [info, setInfo] = useState<any>({});
    const [selectedTab, selectTab] = useState<number>(0);

    const userInfo = useSelector((state: ReduxState) => state.user);
    const currentUserId = userInfo?.id ?? '';
    const canManageStaff = userInfo?.canManageStaff ?? false;

    const [selectedDay, setSelectedDay] = useState<string | undefined>(undefined);
    const [substitute, setSubstitute] = useState<string | undefined>(undefined);

    const selectedSession = useMemo(() => {
        if (!info?.sessions) return null;

        return info.sessions.filter((x: any) => x.userEnabled)[selectedTab];
    }, [info, selectedTab]);

    const groupsForRender = useMemo(() => {
        if (!selectedSession) return [];

        const days = selectedSession.days.reduce((prev: any, curr: any) => {
            const currMonth = moment(curr.date).format('M');

            const currMonthData = {
                ...prev.find((x: any) => x.month === currMonth) ?? {
                    month: currMonth,
                    days: []
                }
            };

            currMonthData.days = [...currMonthData.days, curr];

            return [...prev.filter((x: any) => x.month !== currMonth), currMonthData];
        }, []);

        return days;
    }, [selectedSession]);

    const maximumTrainersInCourse = useMemo(() => {
        const value = Math.max(...(groupsForRender.map((g: any) => {
            const partialTrainers = g.days.map((sess: any) => {
                return sess.trainers.length;
            });

            return Math.max(...partialTrainers);
        }).flat()));

        if (isNaN(value) || value < 0) return 0;
        return value;
    }, [groupsForRender]);

    const [isSelectFacilityDialogOpen, setIsSelectFacilityDialogOpen] = useState(false);
    const [isSelectTrainerDialogOpen, setIsSelectTrainerDialogOpen] = useState(false);
    const [isSelectTrainerDialogSubstituteMode, setIsSelectTrainerDialogSubstituteMode] = useState(false);
    const [selectedSessionId, setSelectedSessionId] = useState<string | undefined>(undefined);
    const [selectedTrainerId, setSelectedTrainerId] = useState<string | undefined>(undefined);

    const [trainerIdsInSession, setTrainerIdsInSession] = useState<string[]>([]);

    const refreshData = useCallback(() => {
        api.request('/admin/winter_staff/schedule/pps_ms/' + (townId ?? courseLocationId)).then(res => {
            setInfo(res);
            setIsLoading(false);
        })
    }, [setIsLoading, townId, courseLocationId]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            refreshData();
        }, 10);

        return () => clearTimeout(timeout);
    }, [refreshData]);

    const getTextColor = (background: string) => {
        const r = parseInt(background.slice(1, 3), 16);
        const g = parseInt(background.slice(3, 5), 16);
        const b = parseInt(background.slice(5, 7), 16);

        if ((r * 0.299) + (g * 0.587) + (b * 0.114) > 186) {
            //console.log('black ' + background);
            return '#000000';
        } else {
            //console.log('white ' + background)
            return '#ffffff';
        }
    }

    const isMobile = width <= 700;

    return (
        <PageContainer>
            <div>
                <div style={{ display: isMobile ? undefined : 'flex', textAlign: isMobile ? 'center' : undefined }}>
                    <Typography variant='h2' style={{ fontSize: isMobile ? '3em' : '3.6em', textAlign: isMobile ? 'center' : 'left', marginTop: '6px' }}>
                        {courseLocationId ? ((info?.courseName ?? '') + (info?.courseCategorySlug !== 'school_courses' ? (' ' + (info?.townName ?? '')) : '')) : (info?.townName ?? '')}
                    </Typography>

                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '22px' }}>
                        <div style={{ flexGrow: 1 }} />
                        <Typography variant='h2' style={{ fontSize: '2em', marginBottom: '4px', textAlign: isMobile ? 'center' : 'left' }}>
                            {selectedSession ? (
                                <>
                                    {moment(selectedSession.startTime).format('dddd')}{' '}
                                    {moment(selectedSession.startTime).format('HH:mm')} - {moment(selectedSession.endTime).format('HH:mm')}<br />
                                    {selectedSession.scheduleDesc}
                                </>
                            ) : ''}
                        </Typography>
                    </div>

                    <div style={{ flex: 1, flexGrow: 1 }} />

                    {/*
                    <Button variant='outlined' onClick={() => {
                        console.log('boh')
                    }} style={{ flexGrow: 0, marginTop: isMobile ? '10px' : undefined, marginBottom: isMobile ? '10px' : undefined, height: 'fitContent', margin: 'auto' }}>
                        Imposta giorni sospensione
                    </Button>
                    */}
                </div>

                <Divider style={{ margin: '8px 0 12px' }} />
            </div>

            <div style={{ flexGrow: 1, overflowY: 'auto' }}>
                <div style={{ maxHeight: '100%', paddingBottom: '16px', display: 'flex', marginLeft: '10px', overflowX: isMobile ? 'auto' : undefined }}>
                    <div>
                        <TableContainer component={Paper} style={{ marginTop: '16px', width: 'auto' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: '150px', whiteSpace: 'nowrap' }}>Data lezione</TableCell>

                                        {[...Array(maximumTrainersInCourse + (canManageStaff ? 1 : 0)).keys()].map((trainerIdx: number) => {
                                            return (
                                                <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Istruttore {trainerIdx + 1}</TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {groupsForRender.map((g: any, idx: number) => {
                                        return (
                                            <>
                                                {idx > 0 && (
                                                    <TableRow>
                                                        <TableCell colSpan={6 + maximumTrainersInCourse + (canManageStaff ? 1 : 0)} style={{ backgroundColor: '#fafafa' }} />
                                                    </TableRow>
                                                )}

                                                <TableRow>
                                                    <TableCell style={{ backgroundColor: '#fafafa', fontWeight: 'bold', textTransform: 'uppercase', textAlign: 'center' }}>
                                                        {moment().month(g.month - 1).format("MMMM")}
                                                    </TableCell>
                                                    <TableCell colSpan={6 + maximumTrainersInCourse} style={{ backgroundColor: '#fafafa', fontWeight: 'bold', textTransform: 'uppercase' }} />
                                                </TableRow>

                                                {g.days.map((day: any) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', textAlign: 'center', backgroundColor: day.active ? undefined : '#ff0000' }}>
                                                                {moment(day.date).format('DD')}
                                                            </TableCell>

                                                            {day.active ? (
                                                                <>
                                                                    {[...Array(maximumTrainersInCourse + (canManageStaff ? 1 : 0)).keys()].map((trainerIdx: number) => {
                                                                        return (
                                                                            <TableCell style={{ width: '150px', whiteSpace: 'nowrap', padding: 0 }}>
                                                                                {day.trainers[trainerIdx] ? (
                                                                                    <StyledListItem backgroundColor={canManageStaff ? ('#' + day.trainers[trainerIdx].scheduleColor) : (day.trainers[trainerIdx].id === currentUserId ? '#f1dd38' : '#e8f4fd')} disableOnHover={!canManageStaff} alignItems="flex-start" style={{ width: '150px', padding: '0px 6px', height: '58px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                                                                        onClick={() => {
                                                                                            if (!canManageStaff) return;
                                                                                            setSelectedSessionId('xxx');
                                                                                            setSelectedTrainerId(day.trainers[trainerIdx].id);
                                                                                            setSelectedDay(day.date);
                                                                                            setSubstitute(selectedSession.substitute ? selectedSession.substitute.superUser.id : undefined);
                                                                                            setIsSelectTrainerDialogOpen(true);
                                                                                            setIsSelectTrainerDialogSubstituteMode(false);
                                                                                            setTrainerIdsInSession(day.trainers.map((t: any) => t.id))
                                                                                        }}
                                                                                        // @ts-ignore
                                                                                        button={canManageStaff}
                                                                                    >
                                                                                        <ListItemText
                                                                                            primary={(
                                                                                                <DynamicTooltipFacilityName description={day.trainers[trainerIdx].firstName + ' ' + day.trainers[trainerIdx].lastName} textColor={getTextColor(canManageStaff ? ('#' + day.trainers[trainerIdx].scheduleColor) : (day.trainers[trainerIdx].id === currentUserId ? '#f1dd38' : '#e8f4fd'))} />
                                                                                            )}
                                                                                            secondary={day.trainers[trainerIdx].startEndTimeDesc ? (
                                                                                                <div style={{ textAlign: 'center' }}>
                                                                                                    {day.trainers[trainerIdx].startEndTimeDesc}
                                                                                                </div>
                                                                                            ) : undefined}
                                                                                        />
                                                                                    </StyledListItem>
                                                                                ) : (
                                                                                    <div style={{ height: '58px', display: 'flex', alignItems: 'center', padding: '8px 22px' }}>
                                                                                        {canManageStaff && (
                                                                                            <Button
                                                                                                variant='outlined'
                                                                                                color='primary'
                                                                                                style={{ margin: '0 auto', display: 'block', width: '100%', height: '32px' }}
                                                                                                size='small'
                                                                                                onClick={() => {
                                                                                                    if (!canManageStaff) return;
                                                                                                    setSelectedSessionId('xxx');
                                                                                                    setSelectedTrainerId(undefined);
                                                                                                    setSelectedDay(day.date);
                                                                                                    setSubstitute(selectedSession.substitute ? selectedSession.substitute.superUser.id : undefined);
                                                                                                    setIsSelectTrainerDialogOpen(true);
                                                                                                    setIsSelectTrainerDialogSubstituteMode(false);
                                                                                                    setTrainerIdsInSession(day.trainers.map((t: any) => t.id))
                                                                                                    console.log('DAY', day);
                                                                                                    console.log(day.trainers);
                                                                                                }}>
                                                                                                <AddIcon />
                                                                                            </Button>
                                                                                        )}
                                                                                    </div>
                                                                                )}
                                                                            </TableCell>
                                                                        )
                                                                    })}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <TableCell colSpan={maximumTrainersInCourse + (canManageStaff ? 1 : 0)} style={{ backgroundColor: '#ff0000' }} />
                                                                </>
                                                            )}


                                                        </TableRow>
                                                    )
                                                })}
                                            </>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>

            <Footer categories={(info?.sessions ?? []).filter((x: any) => x.userEnabled)} selectedTab={selectedTab} selectTab={selectTab} isWeekVisible={true} />

            <SelectFacilityDialog
                open={isSelectFacilityDialogOpen}
                closeDialog={(shouldRefresh?: boolean) => {
                    setIsSelectFacilityDialogOpen(false);

                    if (shouldRefresh) refreshData();
                }}
                sessionId={selectedSessionId}
            />

            <SelectTrainerDialog
                open={isSelectTrainerDialogOpen}
                closeDialog={(shouldRefresh?: boolean) => {
                    setIsSelectTrainerDialogOpen(false);

                    if (shouldRefresh) refreshData();
                }}
                sessionId={selectedSession?.id ?? undefined}
                trainerId={selectedTrainerId}
                substitute={isSelectTrainerDialogSubstituteMode}
                trainerIdsInSession={trainerIdsInSession}
                day={selectedDay}
                higlightItem={substitute}
            />
        </PageContainer >
    );
};

export default WinterSchedulePage;