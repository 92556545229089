import { useRef, useEffect, useState, useCallback } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import api from '../../../../../../common/utils/api';
import { v4 as uuidv4 } from 'uuid';
import 'moment/locale/it';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import snackbar from '../../../../../../common/utils/snackbar';

interface Props {
    open: boolean;
    closeDialog: (shouldRefresh?: boolean) => void;
    superUserId: string;
    type: 'winter_credits' | 'extra_work' | 'bonus'
}

const TYPE_DESC = {
    winter_credits: 'Rimanenze inverno',
    extra_work: 'Lavori extra estate',
    bonus: 'Premi'
};

const ChangeExtraWageDialog = (props: Props) => {
    const { open, closeDialog, superUserId, type } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [wage, setWage] = useState('0.00');
    const [notes, setNotes] = useState('');

    const [data, setData] = useState<GenericObject>({});
    const apiRequestId = useRef('');

    useEffect(() => {
        if (!superUserId || !type || !open) return;

        const currentRequestId = uuidv4();
        apiRequestId.current = currentRequestId;
        setIsLoading(true);

        api.request('/admin/summer_payouts/' + superUserId + '/extra/' + type).then(res => {
            if (currentRequestId !== apiRequestId.current) return;

            setData(res);
            setWage(parseFloat(res.amount).toFixed(2));
            setNotes(res.notes);
            setIsLoading(false);
        })
    }, [superUserId, type, open]);

    useEffect(() => {
        if (!open) return;

        setData({});
        setWage('0.00');
        setNotes('');
    }, [open]);

    const saveData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/summer_payouts/' + superUserId + '/extra/' + type, 'POST', { wage, notes }).then(res => {
            setIsLoading(false);
            snackbar.success('Compenso salvato con successo!');
            closeDialog(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [closeDialog, superUserId, type, wage, notes]);

    const buttons: ButtonProps[] = [
        {
            children: 'Annulla',
            color: 'primary',
            onClick: () => closeDialog()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => saveData()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={TYPE_DESC[type] + (data.trainerName ? (' - ' + data.trainerName) : '')} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <CustomTextField
                label={'Compenso'}
                variant='outlined'
                size='small'
                value={wage === 'NaN' ? '0.00' : wage}
                keepMounted={true}
                onChange={(e) => {
                    setWage(e.target.value);
                }}
                onBlur={() => {
                    setWage((val: any) => {
                        return Math.max(((typeof val === 'string') ? parseFloat(val.replaceAll(',', '.')) : val), type === 'winter_credits' ? -Infinity : 0).toFixed(2);
                    });
                }}
                disabled={isLoading}
            />

            <CustomTextField
                label={'Note'}
                variant='outlined'
                size='small'
                value={notes}
                keepMounted={true}
                onChange={(e) => {
                    setNotes(e.target.value);
                }}
                rows={2}
                multiline
                disabled={isLoading}
            />
        </CustomDialogWrapper >
    );
};

export default ChangeExtraWageDialog;
