import { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import snakecaseKeys from 'snakecase-keys';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import useRegistrationLogic from '../../../../common/hooks/useRegistrationLogic/useRegistrationLogic';
import api from '../../../../common/utils/api';
import GenericObject from '../../../../typesAdditional/GenericObject';
import InternalForm from './components/InternalForm/InternalForm';
import CourseSelector from './sections/CourseSelector/CourseSelector';
import { Title } from './styled';
import { v4 as uuidv4 } from 'uuid';

const NewRegistrationPage = () => {
    const setIsLoadingExternal = useInternalLoader();

    const { width } = useWindowSize();

    const [isLoading, setIsLoading] = useState(false);

    const [seasons, setSeasons] = useState<GenericObject[]>([]);
    const [courses, setCourses] = useState<GenericObject[]>([]);

    const [seasonId, setSeasonId] = useState('');
    const [courseId, setCourseId] = useState<string>('');
    const [selectedLocationId, setSelectedLocationId] = useState<string>('');

    const {
        courseData,
        onChange,
        rawOnChange,
        registrationData,
        validationResult,
        isParticipantMinor
    } = useRegistrationLogic({ courseId, setIsLoading });

    const apiRequestId1 = useRef('');
    const apiRequestId2 = useRef('');
    const apiRequestId3 = useRef('');

    useEffect(() => {
        // request 1
        const currentRequestId = uuidv4();
        apiRequestId1.current = currentRequestId;
        api.request('/admin/seasons').then((resSeasons: any) => {
            if (currentRequestId !== apiRequestId1.current) return;

            setSeasons(resSeasons);

            const wantedSeasonId = localStorage.getItem('courses_season_id') ?? 'cc2ad935-599b-4d4a-a08a-3cc71687eb44';

            if (resSeasons.find((s: any) => s.id === wantedSeasonId)) {
                setSeasonId(wantedSeasonId ?? '');
            } else {
                setSeasonId(resSeasons[0]?.id ?? '');
            }
        }).catch(() => {
            setIsLoading(false);
        });
    }, [setIsLoading]);

    useEffect(() => {
        // request 2
        const currentRequestId = uuidv4();
        apiRequestId2.current = currentRequestId;
        api.request('/admin/courses', 'GET', { season: seasonId }).then((res: any) => {
            if (currentRequestId !== apiRequestId2.current) return;

            setCourses(res);
        }).finally(() => {
            setIsLoading(false);
            setIsLoadingExternal(false);
        });
    }, [seasonId, setIsLoadingExternal, setIsLoading]);

    useEffect(() => {
        if (localStorage.getItem('new_registration_person_id')) {
            const personId = localStorage.getItem('new_registration_person_id');
            localStorage.setItem('new_registration_person_id', '');

            // request 3
            const currentRequestId = uuidv4();
            apiRequestId3.current = currentRequestId;
            api.request('/admin/people', 'GET', { q: personId, page: 1, perPage: 1 }).then((res: any) => {
                if (currentRequestId !== apiRequestId3.current) return;

                const { data } = res;

                if (data.length > 0) {
                    const person = data[0];

                    rawOnChange('participant', snakecaseKeys(person));

                    setTimeout(() => {
                        if (person.parent) {
                            rawOnChange('parent', {
                                ...snakecaseKeys(person.parent),
                                email: person.parent.emailsPhones[0] ? person.parent.emailsPhones[0].email : '',
                                phone: person.parent.emailsPhones[0] ? person.parent.emailsPhones[0].phone : '',
                            });
                        }
                    }, 1500);

                    setTimeout(() => {
                        if (person.secondParent) {
                            rawOnChange('second_parent', {
                                ...snakecaseKeys(person.secondParent),
                                email: person.secondParent.emailsPhones[0] ? person.secondParent.emailsPhones[0].email : '',
                                phone: person.secondParent.emailsPhones[0] ? person.secondParent.emailsPhones[0].phone : '',
                            });
                        }
                    }, 3000);
                }
            });
        }
    }, [rawOnChange]);

    // console.log(registrationData);

    return (
        <>
            <Title isMobile={width <= 700}>
                Aggiungi iscrizione
            </Title>

            <div style={{ maxWidth: '1000px' }}>

                <CourseSelector
                    courseId={courseId}
                    selectedLocationId={selectedLocationId}
                    setCourseId={setCourseId}
                    setSelectedLocationId={setSelectedLocationId}
                    setSelectedSessions={(_sessions: any) => undefined}
                    isLoading={isLoading}
                    courses={courses}
                    courseData={courseData}
                    seasons={seasons}
                    seasonId={seasonId}
                    setSeasonId={setSeasonId}
                />

                {courseData?.id && (
                    <InternalForm
                        courseId={courseId}
                        courseData={courseData}
                        selectedLocationId={selectedLocationId}
                        setSelectedLocationId={setSelectedLocationId}
                        onChange={onChange}
                        rawOnChange={rawOnChange}
                        registrationData={registrationData}
                        validationResult={validationResult}
                        isLoading={isLoading}
                        isParticipantMinor={isParticipantMinor}
                    />
                )}




            </div>
        </>
    );
};

export default NewRegistrationPage;
