import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useWindowSize } from "react-use";
import Button from "@material-ui/core/Button";
import api from "../../../../../../common/utils/api";
import { downloadBlob } from "download.js";
import { useState, useCallback } from "react";
import ZoomControl from "../../../summerSchedulePage/components/ZoomControl/ZoomControl";

interface Props {
    incrementZoom: () => void;
    decrementZoom: () => void;
    zoomLevel: number;
    setZoomLevel: (zoom: string) => void;
    season: 'summer' | 'winter' | 'winter_facilities';
}

const Header = ({ season, ...zoomControlProps }: Props) => {
    const { width } = useWindowSize();
    const isMobile = width <= 700;

    const [isLoading, setIsLoading] = useState(false);

    const base64toBlob = useCallback((dataURI: string) => {
        // convert a base64 string to a blob object
        const byteString = atob(dataURI);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab]);
    }, []);

    const handleExport = () => {
        setIsLoading(true);
        api.request('/admin/summer_payouts/export', 'GET').then(res => {
            const blob = base64toBlob(res.content);
            downloadBlob(res.filename, blob);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <div style={{ display: isMobile ? undefined : 'flex', textAlign: isMobile ? 'center' : undefined }}>
            <div style={{ display: 'flex', flexGrow: 1 }}>
                <Typography variant='h2' style={{ fontSize: '3.6em' }}>
                    {season === 'winter_facilities' ? 'Costi palestre' : 'Compensi istruttori'}
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '18px' }}>
                    <div style={{ flexGrow: 1 }} />
                    <Typography variant='h2' style={{ fontSize: '2em', marginBottom: '4px' }}>
                        {season === 'summer' ? 'estate 2024' : 'inverno 2024/2025'}
                    </Typography>
                </div>
            </div>

            {!isMobile && <ZoomControl {...zoomControlProps} style={{ marginTop: '7px', marginRight: '4px' }} />}

            {season === 'summer' && (
                <Button disabled={isLoading} variant={'contained'} color='primary' onClick={() => handleExport()} style={{ height: 'fit-content', margin: 'auto', flexGrow: 0, marginTop: isMobile ? '10px' : 'auto', marginBottom: isMobile ? '10px' : 'auto' }}>
                    Esporta tabella
                </Button>
            )}

            <Divider style={{ margin: '16px 0' }} />
        </div>
    )
}

export default Header;