import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import { Button, Fab, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EuroIcon from '@material-ui/icons/Euro';
import DescriptionIcon from '@material-ui/icons/Description';
import Badge from '@material-ui/core/Badge';

import CustomTextField from '../../../../common/components/CustomTextField/CustomTextField';
import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import GenericObject from '../../../../typesAdditional/GenericObject';
import { Title } from './styled';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux/types';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ResponsibleDialog from './components/ResponsiblesDialog/ResponsiblesDialog';

const RegistrationsPage = () => {
    const setIsLoading = useInternalLoader();

    const { width } = useWindowSize();
    const history = useHistory();

    const [courses, setCourses] = useState<GenericObject[]>([]);
    const [seasons, setSeasons] = useState<GenericObject[]>([]);
    const [seasonId, setSeasonId] = useState('');

    const [isResponsibleDialogOpen, setIsResponsibleDialogOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<GenericObject | undefined>(undefined);

    const userInfo = useSelector((state: ReduxState) => state.user);
    const isAdmin = !!userInfo.administrator;

    useEffect(() => {
        api.request('/admin/seasons').then((resSeasons: any) => {
            setSeasons(resSeasons);

            const wantedSeasonId = localStorage.getItem('courses_season_id') ?? 'cc2ad935-599b-4d4a-a08a-3cc71687eb44';

            if (resSeasons.find((s: any) => s.id === wantedSeasonId)) {
                setSeasonId(wantedSeasonId ?? '');
            } else {
                setSeasonId(resSeasons[0]?.id ?? '');
            }
        }).catch(() => {
            setIsLoading(false);
        });
    }, [setIsLoading]);

    const refreshData = useCallback(() => {
        if (!seasonId) return;

        api.request('/admin/courses', 'GET', { season: seasonId }).then((res: any) => {
            setCourses(res);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [seasonId, setIsLoading]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const isMobile = width <= 700;

    return (
        <>
            <Title isMobile={isMobile}>
                <div style={{ flex: '1', flexGrow: 1 }}>
                    Gestione iscrizioni
                </div>
                <div style={{ flex: '1', flexGrow: 1, maxWidth: '200px', marginTop: '4px' }}>
                    <CustomTextField
                        select
                        label={'Stagione iscrizioni'}
                        value={seasonId}
                        onChange={(e: any) => {
                            setSeasonId(e.target.value);
                            localStorage.setItem('courses_season_id', e.target.value);
                        }}
                        variant='outlined'
                        required
                        fullWidth
                    >
                        {seasons.map((opt: any) => (
                            <MenuItem key={opt.id} value={opt.id} style={{ whiteSpace: 'break-spaces' }}>
                                {opt.name}
                            </MenuItem>
                        ))}
                    </CustomTextField>
                </div>
            </Title>

            {courses.sort((c1, c2) => c1.order - c2.order).map(course => {
                return (
                    <div style={{ marginBottom: '32px' }}>
                        <Typography variant='h5' style={{ fontSize: '1.7em', marginBottom: '6px', marginLeft: '4px' }}>
                            {course.name}
                        </Typography>

                        <TableContainer component={Paper} style={{ maxWidth: isAdmin ? '740px' : '590px' }}>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Paese</TableCell>
                                        <TableCell style={{ width: '240px' }}>Numero iscrizioni</TableCell>
                                        <TableCell style={{ width: '200px' }}>Elenco</TableCell>
                                        {isAdmin && <TableCell style={{ width: '120px' }}>Responsabili</TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {course.locations.sort((l1: any, l2: any) => l1.town.name.localeCompare(l2.town.name)).map((location: any) => {
                                        return (
                                            <>
                                                <TableRow>
                                                    <TableCell>{location.townName}</TableCell>
                                                    <TableCell>
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ width: '76px' }}>
                                                                {location.registrationCount}
                                                            </div>
                                                            <div style={{ whiteSpace: 'nowrap' }}>
                                                                <Badge badgeContent={location.countPaidRegistrations} max={9999} color="primary" style={{ marginRight: '24px' }}>
                                                                    <EuroIcon />
                                                                </Badge>

                                                                {(!course.season.name.toLowerCase().startsWith('estiva') || course.name.toLowerCase().includes('aquilone') || course.name.toLowerCase().includes('verdeacqua')) && (
                                                                    <Badge badgeContent={location.countUploadedDocuments} max={9999} color="primary" style={{ marginRight: '24px' }}>
                                                                        <DescriptionIcon />
                                                                    </Badge>
                                                                )}

                                                                {(!!location.waGroupEnabled) && isAdmin && (
                                                                    <Badge badgeContent={location.countUsersInWaGroup} max={9999} color="primary">
                                                                        <WhatsAppIcon />
                                                                    </Badge>
                                                                )}

                                                            </div>
                                                        </div>

                                                    </TableCell>
                                                    <TableCell>
                                                        <Button onClick={() => history.push((window.location.host.startsWith('admin.epuntos.it') ? '' : '/admin') + '/registrations/' + course.id + '/locations/' + location.id)} color={'primary'}>
                                                            Visualizza elenco
                                                        </Button>
                                                    </TableCell>

                                                    {isAdmin && (
                                                        <TableCell style={{ textAlign: 'center' }}>
                                                            <Fab color="primary" aria-label="add" variant="extended" size="small" onClick={() => {
                                                                setSelectedLocation(location);
                                                                setIsResponsibleDialogOpen(true);
                                                            }}>
                                                                <SupervisorAccountIcon /><span style={{ marginLeft: '6px', marginRight: '3px' }}>{location.countSuperUsers}</span>
                                                            </Fab>
                                                        </TableCell>
                                                    )}
                                                </TableRow>

                                                {location.registrationCountWaitingList > 0 && (
                                                    <TableRow>
                                                        <TableCell>{location.townName} (lista d'attesa)</TableCell>
                                                        <TableCell>{location.registrationCountWaitingList}</TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => history.push((window.location.host.startsWith('admin.epuntos.it') ? '' : '/admin') + '/registrations/' + course.id + '/locations/' + location.id + '?waiting_list=true')} color={'primary'}>
                                                                Visualizza elenco
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                );
            })}

            {isAdmin && (
                <ResponsibleDialog open={isResponsibleDialogOpen} closeDialog={() => setIsResponsibleDialogOpen(false)} location={selectedLocation} refreshData={refreshData} />
            )}

        </>
    );
};

export default RegistrationsPage;
